
























import { Component, Vue } from 'vue-property-decorator';
import { AncienContratServices } from '@/services';
import { ancienContrat, taches, toast } from '@/store';
import { ERRORTYPE, HttpAuthTokenHelper, NavigationHelper } from '@projetlucie/lc-front-sdk-old';
import { ANCIENCONTRATCHOICES, pagePath } from '@/utils';
import { ONBOARDINGTACHESTATEDTO } from '@/enums';
import { ChoiceMoi, ChoicePremierContrat, ChoiceMutuelle } from '@/components';

@Component({
  components: { ChoiceMoi, ChoicePremierContrat, ChoiceMutuelle }
})
export default class OnboardingAncienContrat extends Vue {
  private choice: ANCIENCONTRATCHOICES | null = null;
  private resiliationMutuelle = false;

  private selectChoice(choice: ANCIENCONTRATCHOICES) {
    this.choice = choice;
  }

  created() {
    ancienContrat.initMailModel();
    this.resiliationMutuelle = ancienContrat.getResiliationStatus;

    if (!this.referenceTache) return;
    this.choice =
      this.referenceTache.selectedValue == ANCIENCONTRATCHOICES.MUTUELLE
        ? ANCIENCONTRATCHOICES.MUTUELLE
        : this.referenceTache.selectedValue == ANCIENCONTRATCHOICES.MOI
        ? ANCIENCONTRATCHOICES.MOI
        : this.referenceTache.selectedValue == ANCIENCONTRATCHOICES.PREMIERCONTRAT
        ? ANCIENCONTRATCHOICES.PREMIERCONTRAT
        : null;
  }

  public async sendMutuelleTermination(npp: string) {
    if (!ancienContrat.getResiliationClaimStatus) {
      ancienContrat.saveResiliationClaimStatusInStore(true);
      await AncienContratServices.sendMutuelleTermination(npp);
    }
  }

  private valid() {
    if (!this.choice || !this.referenceTache)
      return toast.createAndPushToast(
        this.$t('onboarding.ancienContrat.error.title').toString(),
        this.$t('onboarding.ancienContrat.error.subtitle').toString(),
        true,
        ERRORTYPE.INFO
      );
    if (this.choice === ANCIENCONTRATCHOICES.MUTUELLE) {
      this.sendMutuelleTermination(HttpAuthTokenHelper.decode().npp);
    }
    taches.updateTache({ tache: this.referenceTache, statut: ONBOARDINGTACHESTATEDTO.FINISH });
    this.$router.push(pagePath.VueEnsemble);
  }

  get referenceTache() {
    return taches.currentTache;
  }

  public goBack() {
    NavigationHelper.goBack(this.$router);
  }
}
